import React from "react";
import { Container } from 'reactstrap';

import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import Header from "../components/Header/Header";
import Favicon from "../components/Favicon";
import Footer from "../components/Footer";


import '../../scss/style.scss';
import '../../scss/layout.scss';

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <Container>
        <Helmet>
          <meta name="description" content={config.siteDescription} />
        </Helmet>
        <Favicon />
        <Header />
        <div role="main">
          {children}
        </div>
        <Footer />
      </Container>
    );
  }
}
