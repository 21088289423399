import React, { Component } from "react";
import Helmet from "react-helmet";
import config from '../../../data/SiteConfig'

class Favicon extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/favicons/android-chrome-192x192.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
        <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#ffb84e" />

        <meta name="apple-mobile-web-app-title" content={config.siteTitle} />
        <meta name="application-name" content={config.siteTitle} />
        <meta name="msapplication-TileColor" content={config.backgroundColor} />
        <meta name="msapplication-TileImage" content="/favicons/mstile-144x144.png" />
        <meta name="theme-color" content={config.themeColor} />
      </Helmet>
    );
  }
}

export default Favicon;
