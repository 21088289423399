import React, { Component } from "react";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "gatsby"

import "./header.scss";
import logo from "../../../static/assets/logo-nav.png";

class Header extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };

    this.changeCss = this.changeCss.bind(this);
  }

  componentDidMount () {
        window.addEventListener("scroll", this.changeCss , false);
  }

  componentWillUnmount() {
      window.removeEventListener("scroll", this.changeCss , false);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  changeCss () {
    const navbrand =  document.getElementById('navbrand');
    const navbrandImg =  document.getElementById('navbrand-img');


    if(window.pageYOffset > 150) {
      navbrand.classList.add("windows-scrolled");
      navbrand.classList.remove("windows-not-scrolled")
    } else {
      navbrand.classList.add("windows-not-scrolled");
      navbrand.classList.remove("windows-scrolled")
    }
  }

  makeLink(to, label) {
    if (typeof document !== "undefined" && document.location.pathname === '/') {
      return (
        <NavLink
          tag={ScrollLink}
          to={to}
          activeClass="active"
          offset={-70}
          spy
          smooth
        >
          {label}
        </NavLink>
      );
    } else {
      return (
        <NavLink
          tag={Link}
          to={`/#${to}`}
        >
          {label}
        </NavLink>
      )
    }
  }

  render() {
    return (
      <Navbar color="dark" dark expand="md">
        <NavbarBrand href="/" id="navbrand">
          <span>EMES Consuting UG</span>
          <img src={logo}  id="navbrand-img"/>

        </NavbarBrand>

        <NavbarToggler className="custom-toggler" onClick={this.toggle} />



        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              {this.makeLink('home', 'Home')}
            </NavItem>
            <NavItem>
              {this.makeLink('service', 'Datenschutz')}
            </NavItem>
            <NavItem>
              {this.makeLink('method', 'Social Media')}
            </NavItem>
            <NavItem>
              {this.makeLink('references', 'Referenzen')}
            </NavItem>
            <NavItem>
              {this.makeLink('contact', 'Kontakt')}
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default Header;
