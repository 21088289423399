import React, { Component } from "react";
import { Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Link } from "gatsby"


import "./footer.scss";

class Footer extends Component {
  render() {

    return (
      <footer className="footer">
        <Row>
          <Col md={{ size: 3, offset: 3 }} sm={12}>
            <div className='logo-footer' />
            <Nav vertical>
              <NavItem>
                <NavLink href="mailto:info@emes-consulting.com">
                  <i className='mail-icon' />
                  {" "}
                  info@emes-consulting.com
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="tel:+49 1590 4262302">
                  <i className='tel-icon' />
                  {" "}
                  +49 221 / 3555 4444
                </NavLink>
              </NavItem>
            </Nav>
          </Col>

          <Col md={3} sm={12} className='mt-md-0 mt-4'>
            <h4>Links</h4>
            <Nav vertical>
              <NavItem>
                <NavLink tag={Link} to="/impressum" >Impressum</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/datenschutz">Datenschutz</NavLink>
              </NavItem>
            </Nav>

            <br/>
            <h4>Ressourcen</h4>
            <Nav vertical>
              <NavItem>
                <NavLink><a target="blank" href="https://www.gdd.de/">GDD</a></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><a target="blank" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">DSGVO</a></NavLink>
              </NavItem>
            </Nav>
          </Col>

          <Col md={3} sm={12} className='mt-md-0 mt-4'>
            <h4>Sonstiges</h4>
            <Nav vertical>
              <NavItem>
                <NavLink href="#"><img src="https://image.jimcdn.com/app/cms/image/transf/dimension=153x10000:format=gif/path/s778863b735b982bc/image/i62ac5bf9e364457e/version/1535138726/allianz-f%C3%BCr-cyber-sicherheit.gif" alt=""/></NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <Row className='sub-footer-nav'>
          <Col md={12}>
            <div className="copyright-footer">
              Copyright by EMES Consuting UG (haftungsbeschränkt) 2018
            </div>
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
