module.exports = {
  siteTitle: "EMES Consulting", // Site title.
  siteTitleShort: "EMES", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "EMES Consulting", // Alternative site title for SEO.
  siteLogo: "/favicons/android-chrome-256x256.png", // Logo used for SEO and manifest.
  siteUrl: "https://emes-consulting.com/", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription: "", // Website description used for RSS feeds/meta description tag.
  siteRss: "/rss.xml", // Path to the RSS file.
  googleAnalyticsID: "UA-127676975-1", // GA tracking ID.
  userName: "Advanced User", // Username to display in the author segment.
  userLocation: "North Pole, Earth", // User location to display in the author segment.
  userAvatar: "https://api.adorable.io/avatars/150/test.png", // User avatar to display in the author segment.
  siteFBAppID: "1825356251115265", // FB Application ID for using app insights
  userTwitter: "", // Optionally renders "Follow Me" in the UserInfo segment.
  userDescription:
    "Yeah, I like animals better than people sometimes... Especially dogs. Dogs are the best. Every time you come home, they act like they haven't seen you in a year. And the good thing about dogs... is they got different dogs for different people.", // User description to display in the author segment.
  copyright: "Copyright © 2017. Advanced User", // Copyright string for the footer of the website and RSS feed.
  themeColor: "#194255", // Used for setting manifest and progress theme colors.
  backgroundColor: "#194255" // Used for setting manifest background color.
};
